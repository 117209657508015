/* eslint @typescript-eslint/no-var-requires: "off" */

import { ChangeEvent, FC, useEffect, useState } from "react";
import { Box } from "@twilio-paste/core/box";
import { Select, Option } from "@twilio-paste/core/select";
import { Label } from "@twilio-paste/core/label";
import { Text } from "@twilio-paste/core/text";
import { Table, TBody, Td, Th, THead, Tr } from "@twilio-paste/core/table";
import { EditIcon } from "@twilio-paste/icons/esm/EditIcon";
import { Button } from "@twilio-paste/core/button";
import { DeleteIcon } from "@twilio-paste/icons/esm/DeleteIcon";
import { Stack } from "@twilio-paste/core/stack";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalFooterActions,
  ModalHeader,
  ModalHeading,
} from "@twilio-paste/core/modal";
import { useUID } from "@twilio-paste/core/dist/uid-library";
import { useToasterContext } from "../../hooks/useToasterContext";
import { Input } from "@twilio-paste/core/input";
import { Heading } from "@twilio-paste/core/heading";
import { Checkbox, CheckboxGroup } from "@twilio-paste/core/checkbox";
import { PlusIcon } from "@twilio-paste/icons/esm/PlusIcon";
import { HeaderTitleText } from "../HeaderTitleText/HeaderTitleText";
import { useNFCCContext } from "../../hooks/useNFCCContext/useNFCCContext";
import { Holidays, Holiday, HolidayNew } from "@ciptex/nfcc";
import { useAppState } from "../../hooks/useAppState/useAppState";
import { useNavigate, useParams } from "react-router-dom";
import { DatePicker } from "@twilio-paste/core/date-picker";
import { TimePicker } from "@twilio-paste/core/time-picker";
import { DateTime } from "luxon";
import { Alert, HelpText, Paragraph } from "@twilio-paste/core";
import { timezones } from "../../constants";

interface TimezoneDefinition {
    name: string;
    tag: string;
    isDaylightSavings: boolean;
}

interface Timezone {
    timezoneId?: number;
    name?: string;
    nameLong?: string;
    utcOffset?: number;
}

const getIANATimezone = (tzName: string): string => {
    return tzName || 'UTC';
};

const findTimezoneByName = (tzTag: string, timezones: TimezoneDefinition[]): TimezoneDefinition | undefined => {
  return timezones.find(tz => tz.tag === tzTag);
};


const getDisplayTimezones = (timezones: TimezoneDefinition[]): TimezoneDefinition[] => {
  return [...timezones].sort((a, b) => {
      const nameCompare = a.name.localeCompare(b.name);
      if (nameCompare !== 0) return nameCompare;
      
      return a.isDaylightSavings ? 1 : -1;
  });
};

const getServerTimezone = (
  selectedTimezone: TimezoneDefinition,
  serverTimezones: Timezone[]
): Timezone | undefined => {
  if (!selectedTimezone) return undefined;
  
  // Mapping for special cases where tags don't match exactly
  const tagMapping: Record<string, string> = {
      'HSDT': 'HDT',   
      'HAST': 'HST',   
      'NSDT': 'NDT',   
  };

  const serverTag = tagMapping[selectedTimezone.tag] || selectedTimezone.tag;
  
  const serverTimezone = serverTimezones.find(serverTz => serverTz.name === serverTag);
  
  if (!serverTimezone) {
      console.log('No match found for:', {
          originalTag: selectedTimezone.tag,
          mappedTag: serverTag,
          availableTags: serverTimezones.map(tz => tz.name)
      });
  }
  
  return serverTimezone;
};


const normaliseTimezoneFromAPI = (apiTimezone: string | undefined, timezones: TimezoneDefinition[]): string => {
  console.log('Starting timezone normalisation for:', apiTimezone);
  
  if (!apiTimezone) {
      console.warn('No timezone provided from API, defaulting to EST');
      return 'EST';
  }

  const directMatch = timezones.find(tz => tz.tag === apiTimezone);
  if (directMatch) {
      console.log('Found direct match:', directMatch);
      return directMatch.tag;
  }

  // If no direct match, default to EST
  console.warn(`No matching timezone found for ${apiTimezone}, defaulting to EST`);
  return 'EST';
};


export const HolidaysPage: FC = () => {
  const { toaster } = useToasterContext();
  const navigate = useNavigate();
  const {
    getHolidays,
    getAgency,
    listTimezones,
    updateAgency,
    createHoliday,
    updateHoliday,
    deleteHoliday,
  } = useNFCCContext();
  const { appState } = useAppState();
  const [holidays, setHolidays] = useState<any[]>([]);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [tz, setTz] = useState<string>();
  const [deleteIndex, setDeleteIndex] = useState<string>("");
  const [editIndex, setEditIndex] = useState<number>(0);
  const [editingHoliday, setEditingHoliday] = useState<any>();
  const [pageError, setPageError] = useState<boolean>(false);
  const [nameError, setNameError] = useState<string>("");
  const [startDateError, setStartDateError] = useState<string>("");
  const [channelError, setChannelError] = useState<string>("");

  const [editIsOpen, setEditIsOpen] = useState(false);

  const { agencyId } = useParams();

  {
    /* Add holiday modal states */
  }
  const handleAddOpen = () => {
    const newHoliday: any = {
      agencyHolidayConfigId: -Math.floor(Math.random() * 1000 + 1),
      startDate: new Date().toISOString().substring(0, 16),
      endDate: new Date().toISOString().substring(0, 16),
      name: "",
      notes: "",
      channels: [],
    };
    setEditingHoliday(newHoliday);
    setEditIndex(newHoliday.agencyHolidayConfigId);
    setEditIsOpen(true);
  };

  {
    /* Edit holiday modal states */
  }

  const handleEditOpen = (index: number) => {
    setEditIndex(index);
    if (holidays) {
      setEditingHoliday(
        holidays.filter((i) => i.agencyHolidayConfigId === index)[0],
      );
      setEditIsOpen(true);
    }
  };
  const handleEditClose = () => {
    setEditIsOpen(false);
    setNameError("");
    setStartDateError("");
    setPageError(false);
    setChannelError("");
  };
  const editModalHeadingID = useUID();

  {
    /* Delete holiday modal states */
  }
  const [deleteIsOpen, setDeleteIsOpen] = useState(false);
  const handleDeleteOpen = (index: string) => {
    setDeleteIsOpen(true);
    setDeleteIndex(index);
  };
  const handleDeleteClose = () => setDeleteIsOpen(false);
  const deleteModalHeadingID = useUID();

  const tzs = timezones

  useEffect(() => {
    (async () => {
      try {
        const agId = parseInt(agencyId ?? "0") || appState.agencyId;
        const hols: Holidays = await getHolidays(agId);

        const agency = await getAgency(agId);

        console.log('Agency timezone from API:', agency.timezone);
        console.log('Current timezone definitions:', timezones);

        const normalisedTz = normaliseTimezoneFromAPI(agency.timezone, timezones);
        console.log('Final normalised timezone:', normalisedTz);
        setTz(normalisedTz);
        const offset = agency.utcOffset;

        if (hols.length > 0) {
          const hs = hols.map(h => {
            const dtStart = DateTime.fromISO(
                h.dateStart?.replace(" ", "T").split(".")[0],
                { zone: "UTC" }
            );
            const dateStart = dtStart
                .setZone(`UTC${offset}`)
                .toISO();

            const dtEnd = DateTime.fromISO(
                h.dateEnd?.replace(" ", "T").split(".")[0],
                { zone: "UTC" }
            );
            const dateEnd = dtEnd
                .setZone(`UTC${offset}`)
                .toISO();

            return {
                ...h,
                startDate: dateStart,
                endDate: dateEnd
            };
        });

          setHolidays(hs);
          setLoaded(true);
        }
      } catch (error) {
        console.error(error);
        toaster.push({
          message: "Could not retrieve holidays",
          variant: "error",
          dismissAfter: 4000,
        });
      }
    })();
  }, []);

  const onCheckboxChange = ({ target }: any) => {
    if (target.checked) {
      // add to array
      let ch = [];
      if (editingHoliday.connectionTypes) {
        ch = JSON.parse(editingHoliday.connectionTypes);
      }

      ch.push(target.value);
      const h = { ...editingHoliday, connectionTypes: JSON.stringify(ch) };
      setEditingHoliday(h);
      setChannelError("");
    } else {
      // remove from array
      let ch = [];
      if (editingHoliday.connectionTypes) {
        ch = JSON.parse(editingHoliday.connectionTypes);
      }
      const ch1 = ch.filter((s: string) => s !== target.value);
      const h = { ...editingHoliday, connectionTypes: JSON.stringify(ch1) };
      setEditingHoliday(h);
    }
  };

  const formatDateTime = (dt: string): string | undefined => {
    if (!dt) return undefined;

    try {
        const dateTime = DateTime.fromISO(dt);
        if (!dateTime.isValid) {
            console.error('Invalid date format:', dt);
            return undefined;
        }

        return dateTime.toFormat('M/d/yyyy HH:mm');
    } catch (error) {
        console.error('Error formatting date:', error);
        return undefined;
    }
};

  const handleDeleteCloseConfirm = async () => {
    if (holidays) {
      if (deleteIndex.toString().includes("#")) {
        // Two to delete!
        await deleteHoliday(
          parseInt(agencyId ?? "0") || appState.agencyId,
          parseInt(deleteIndex.toString().split("#")[0]),
        );
        await deleteHoliday(
          parseInt(agencyId ?? "0") || appState.agencyId,
          parseInt(deleteIndex.toString().split("#")[1]),
        );
      } else if (parseInt(deleteIndex) > 0) {
        await deleteHoliday(
          parseInt(agencyId ?? "0") || appState.agencyId,
          parseInt(deleteIndex),
        );
      }

      const hols: any[] = (holidays ?? []).filter(
        (s) => s.agencyHolidayConfigId !== deleteIndex,
      );

      setHolidays(hols);
      setDeleteIndex("");
    }

    handleDeleteClose();
  };

  const validate = () => {
    // validate
    if (holidays) {
      let isError = false;

      // check if name blank
      if (editingHoliday.name === "") {
        setNameError("Name cannot be left blank");
        isError = true;
      } else {
        setNameError("");
      }

      if (editingHoliday.startDate > editingHoliday.endDate) {
        setStartDateError("Start date cannot be after end date");
        isError = true;
      } else {
        setStartDateError("");
      }

      if (JSON.parse(editingHoliday.connectionTypes).length === 0) {
        setChannelError("At least one channel must be selected");
        isError = true;
      }

      return isError;
    }
  };

  const handleEditCloseConfirm = async () => {
    const isError = validate();

    if (holidays && !isError) {
      setPageError(false);
      const hols: any[] = holidays.map((x) => x);

      if (editIndex < 0) {
        hols.push(editingHoliday);
        setHolidays(hols);
        setLoaded(true);
      } else {
        const ii = hols.findIndex((object) => {
          return object.agencyHolidayConfigId === editIndex;
        });

        const h = hols.slice(0, ii);
        const he = hols.slice(ii + 1);

        setHolidays(h.concat([{ ...editingHoliday, modified: true }], he)); // splice wasn't working for some reason so here's this monstrosity as i've ran out of patience
      }

      setEditIndex(0);
      handleEditClose();
    } else {
      setPageError(true);
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

	try {
        if (!holidays || !tz) return;

        const agId = parseInt(agencyId ?? "0") || appState.agencyId;
        const agency = await getAgency(agId);
        const serverTimezones = await listTimezones();

        if (!tz) {
            throw new Error("No timezone selected");
        }

        // Find the matching timezone definition based on the selected timezone name
        const selectedTimezone = findTimezoneByName(tz, timezones);

        if (!selectedTimezone) {
          console.error('Selected timezone info not found:', tz);
          console.error('Available timezones:', timezones);
          console.error('Current tz value:', tz);
          throw new Error(`Could not find timezone info for ${tz}. Please select a timezone from the dropdown.`);
      }
      console.log('About to get server timezone:', {
        selectedTimezone,
        timezones,
        serverTimezones
    });
        const serverTimezone = getServerTimezone(selectedTimezone, serverTimezones);
        
        if (!serverTimezone) {
            console.error('Server timezones:', serverTimezones);
            console.error('Selected timezone:', selectedTimezone);
            throw new Error(`Could not match timezone ${selectedTimezone.tag} with server timezones`);
        }

        await updateAgency(agId, {
            ...agency,
            timezoneId: serverTimezone.timezoneId
        });

        // Process holidays
        for (const holiday of holidays) {
            if (holiday.modified || parseInt(holiday.agencyHolidayConfigId) < 0) {
                // Convert dates to UTC using the IANA timezone name
                const ianaTimezone = getIANATimezone(selectedTimezone.name);
                
                // Handle start date
                const startDate = DateTime.fromISO(holiday.startDate, {
                    zone: ianaTimezone
                }).toUTC();

                // Handle end date
                const endDate = DateTime.fromISO(holiday.endDate, {
                    zone: ianaTimezone
                }).toUTC();

                const holidayData = {
                    agencyId: agId,
                    dateStart: startDate.toISO(),
                    dateEnd: endDate.toISO(),
                    notes: holiday.notes,
                    name: holiday.name,
                    connectionTypes: JSON.parse(holiday.connectionTypes)
                };

                if (parseInt(holiday.agencyHolidayConfigId) < 0) {
                    // Create new holiday
                    await createHoliday(agId, holidayData);
                } else {
                    // Update existing holiday
                    await updateHoliday(agId, holiday.agencyHolidayConfigId, {
                        ...holidayData,
                        agencyHolidayConfigId: holiday.agencyHolidayConfigId
                    });
                }
            }
        }

        toaster.push({
            message: "Holidays updated successfully!",
            variant: "success",
            dismissAfter: 4000
        });

        if (appState.role === "nfcc_admin") {
            navigate("/agencies/" + agencyId);
        } else {
            navigate("/home");
        }
    } catch (error) {
      console.error("Holiday update error:", error);
      toaster.push({
          message: error instanceof Error ? error.message : "Could not update holidays",
          variant: "error",
          dismissAfter: 4000
      });
  }
  };

  const handleTimeChange = ({
    target,
  }: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    if (target.id === "startDate") {
      const dt = target.value + "T" + editingHoliday.startDate.split("T")[1];
      setEditingHoliday({ ...editingHoliday, startDate: dt });
    } else if (target.id === "startTime") {
      const dt = editingHoliday.startDate.split("T")[0] + "T" + target.value;
      setEditingHoliday({ ...editingHoliday, startDate: dt });
    } else if (target.id === "endDate") {
      const dt = target.value + "T" + editingHoliday.endDate.split("T")[1];
      setEditingHoliday({ ...editingHoliday, endDate: dt });
    } else if (target.id === "endTime") {
      const dt = editingHoliday.endDate.split("T")[0] + "T" + target.value;
      setEditingHoliday({ ...editingHoliday, endDate: dt });
    }
  };

  const handleChange = ({
    target,
  }: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const h = { ...editingHoliday, [target.name]: target.value };
    setEditingHoliday(h);
  };

const handleChangeTz = ({
  target,
}: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
  
  setTz(target.value);
};

  const handleEnableDisable24hours = (e: any) => {
    if (e.target.checked) {
      const dt = editingHoliday.startDate.split("T")[0] + "T00:00";
      const dt1 = editingHoliday.endDate.split("T")[0] + "T23:59";
      setEditingHoliday({ ...editingHoliday, startDate: dt, endDate: dt1 });
    } else {
      const dt = editingHoliday.startDate.split("T")[0] + "T00:00";
      const dt1 = editingHoliday.endDate.split("T")[0] + "T00:01";
      setEditingHoliday({ ...editingHoliday, startDate: dt, endDate: dt1 });
    }
  };

  function isChecked(): boolean {
    try {
      const startTime = editingHoliday.startDate.split("T")[1];
      const endTime = editingHoliday.endDate.split("T")[1];

      if (startTime === "00:00" && endTime === "23:59") {
        return true;
      } else {
        return false;
      }
    } catch {
      return false;
    }
  }

  return (
    <>
      <Box display="flex" flexDirection="column" rowGap="space40" width="25%">
        <HeaderTitleText titleText="Manage Holidays" />
        <Label htmlFor="timezone">Schedule Timezone</Label>
        {tzs && (
          <Select
            name="timezone"
            id="timezone"
            value={tz}
            onChange={handleChangeTz}
          >
                     {getDisplayTimezones(tzs).map((timezone) => (
            <Option 
                key={`${timezone.name}-${timezone.isDaylightSavings}`}
                value={timezone.tag}
            >
                {timezone.name.replace('_', ' ')} ({timezone.tag})
            </Option>
        ))}
          </Select>
        )}

        <Paragraph marginBottom="space0">
          Please update with any dates that your agency does not wish to receive
          connections. To add a new holiday, click the plus button.
        </Paragraph>
      </Box>

      <Box display="flex" justifyContent="flex-end">
        <Button variant="primary" onClick={handleAddOpen}>
          <PlusIcon decorative={false} title="Add new holiday" />
          Add New Holiday
        </Button>
      </Box>
      <form onSubmit={(e) => handleSubmit(e)}>
        <Box marginY="space60">
          <Table>
            <THead>
              <Tr>
                <Th>Actions</Th>
                <Th>Start</Th>
                <Th>End</Th>
                <Th>Name</Th>
                <Th>Channel(s)</Th>
                <Th>Notes</Th>
              </Tr>
            </THead>
            <TBody>
              {holidays && loaded ? (
                holidays &&
                holidays.map((h: any, rowIndex: number) => (
                  <Tr key={"tr-" + rowIndex}>
                    <Td key={"row-" + rowIndex}>
                      <Stack
                        orientation="vertical"
                        spacing="space60"
                        key={"stack-" + rowIndex}
                      >
                        <Button
                          variant="secondary"
                          onClick={(e) =>
                            handleEditOpen(h.agencyHolidayConfigId)
                          }
                          key={"b1-" + rowIndex}
                        >
                          <EditIcon title="Edit Holiday" decorative={false} />
                        </Button>
                        <Button
                          variant="destructive"
                          onClick={(e) =>
                            handleDeleteOpen(h.agencyHolidayConfigId)
                          }
                          key={"b2-" + rowIndex}
                        >
                          <DeleteIcon
                            title="Delete Holiday"
                            decorative={false}
                          />
                        </Button>
                      </Stack>
                    </Td>
                    <Td key={"startDate-" + rowIndex}>
                      <Paragraph marginBottom="space0">
                        {formatDateTime(h.startDate)}
                      </Paragraph>
                    </Td>
                    <Td key={"endDate-" + rowIndex}>
                      <Text as="p">
                        {h.endDate && formatDateTime(h.endDate)}
                      </Text>
                    </Td>
                    <Td key={"name-" + rowIndex}>
                      <Text as="p">{h.name}</Text>
                    </Td>
                    <Td key={"channels-" + rowIndex}>
                      <Text as="p">
                        {h.connectionTypes
                          .replace("[", "")
                          .replace("]", "")
                          .replaceAll(",", ", ")
                          .replaceAll('"', "")}
                      </Text>
                    </Td>
                    <Td key={"notes-" + rowIndex}>
                      <Text as="p">{h.notes}</Text>
                    </Td>
                  </Tr>
                ))
              ) : (
                <Tr>
                  <Td colSpan={6}>No data to display</Td>
                </Tr>
              )}
            </TBody>
          </Table>
          <Box
            display="flex"
            justifyContent="flex-end"
            position="relative"
            marginY="space60"
          >
            <Button variant="primary" type="submit">
              Save
            </Button>
          </Box>
        </Box>
      </form>

      {/* Add/Edit a Holiday modal */}
      <Modal
        isOpen={editIsOpen}
        onDismiss={handleEditClose}
        size="wide"
        ariaLabelledby={editModalHeadingID}
      >
        <ModalHeader>
          <ModalHeading as="h2" id={editModalHeadingID}>
            Configure Holiday
          </ModalHeading>
        </ModalHeader>

        <ModalBody>
          {pageError && (
            <Box paddingTop="space60" paddingBottom="space60">
              <Alert variant="error">
                <Text as="span">
                  Validation errors have been found on this holiday, please
                  check these before continuing
                </Text>
              </Alert>
            </Box>
          )}

          {editingHoliday && (
            <>
              <Heading as="h4" variant="heading40">
                Please select start date and time, end date and time, label and
                any relevant notes.
              </Heading>

              <Box
                display="flex"
                flexDirection="column"
                marginY="space60"
                padding="space60"
              >
                <Checkbox
                  key="24h"
                  id="24h"
                  checked={isChecked()}
                  name="24h"
                  onClick={(e) => handleEnableDisable24hours(e)}
                >
                  24 hours?
                </Checkbox>

                <Box
                  display="flex"
                  flexDirection="row"
                  marginY="space60"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box display="flex">
                    <Label htmlFor="startDate">Start Date and Time</Label>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    columnGap="space60"
                  >
                    <Box display="flex" flexDirection="column">
                      <DatePicker
                        onChange={handleTimeChange}
                        required
                        id="startDate"
                        enterKeyHint={undefined}
                        value={editingHoliday.startDate.split("T")[0]}
                      />
                    </Box>
                    <TimePicker
                      required
                      id="startTime"
                      name="startTime"
                      value={
                        editingHoliday.startDate.split("T")[1].split(".")[0]
                      }
                      onChange={handleTimeChange}
                      enterKeyHint={undefined}
                    />
                  </Box>
                </Box>

                <Box
                  display="flex"
                  flexDirection="row"
                  marginY="space60"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box display="flex">
                    <Label htmlFor="endDate">End Date and Time</Label>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    columnGap="space60"
                  >
                    <DatePicker
                      onChange={handleTimeChange}
                      required
                      id="endDate"
                      enterKeyHint={undefined}
                      value={editingHoliday.endDate.split("T")[0]}
                    />

                    <TimePicker
                      required
                      id="endTime"
                      name="endTime"
                      value={editingHoliday.endDate.split("T")[1].split(".")[0]}
                      onChange={handleTimeChange}
                      enterKeyHint={undefined}
                    />
                  </Box>
                </Box>

                {startDateError.length > 0 && (
                  <Box display="flex" justifyContent="flex-end">
                    <HelpText variant="error">{startDateError}</HelpText>
                  </Box>
                )}

                <Box
                  display="flex"
                  flexDirection="row"
                  marginY="space60"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box display="flex">
                    <Label htmlFor="name">Name</Label>
                  </Box>
                  <Box display="flex" flexDirection="column">
                    <Box
                      display="flex"
                      justifyContent="flex-end"
                      columnGap="space60"
                      width="25vw"
                      flexDirection="row"
                    >
                      <Input
                        type="text"
                        onChange={handleChange}
                        id="name"
                        name="name"
                        value={editingHoliday.name}
                        required
                        hasError={nameError.length > 0}
                      />
                    </Box>
                    {nameError.length > 0 && (
                      <HelpText variant="error">{nameError}</HelpText>
                    )}
                  </Box>
                </Box>

                <Box
                  display="flex"
                  flexDirection="row"
                  marginY="space60"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box display="flex">
                    <Label htmlFor="channel">Channel</Label>
                  </Box>
                  <Box display="flex" justifyContent="flex-end">
                    <CheckboxGroup
                      orientation="horizontal"
                      name="chk"
                      legend=""
                      helpText=""
                      key="channels"
                    >
                      {editingHoliday &&
                        ["Phone", "Online", "Video"].map(
                          (s: any, index: number) => (
                            <Checkbox
                              key={s}
                              id={s}
                              checked={
                                editingHoliday.connectionTypes?.includes(s) ||
                                false
                              }
                              value={s}
                              name={s}
                              onClick={(e) => onCheckboxChange(e)}
                            >
                              {s}
                            </Checkbox>
                          ),
                        )}
                    </CheckboxGroup>
                  </Box>
                </Box>

                {channelError.length > 0 && (
                  <Box display="flex" justifyContent="flex-end">
                    <HelpText variant="error">{channelError}</HelpText>
                  </Box>
                )}

                <Box
                  display="flex"
                  flexDirection="row"
                  marginY="space60"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box display="flex">
                    <Label htmlFor="notes">Notes</Label>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    columnGap="space60"
                    width="25vw"
                  >
                    <Input
                      placeholder=""
                      name="notes"
                      onChange={handleChange}
                      type="text"
                      value={editingHoliday.notes}
                    />
                  </Box>
                </Box>
              </Box>
            </>
          )}
        </ModalBody>

        <ModalFooter>
          <ModalFooterActions>
            <Button variant="secondary" onClick={handleEditClose}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleEditCloseConfirm}>
              Submit
            </Button>
          </ModalFooterActions>
        </ModalFooter>
      </Modal>

      {/* Delete Holiday modal */}
      <Modal
        isOpen={deleteIsOpen}
        onDismiss={handleDeleteClose}
        size={"default"}
        ariaLabelledby={deleteModalHeadingID}
      >
        <ModalHeader>
          <ModalHeading as="h2" id={deleteModalHeadingID}>
            Delete Holiday
          </ModalHeading>
        </ModalHeader>

        <ModalBody>
          <Box marginY="space60">
            <Text as="p">Are you sure want to delete this holiday?</Text>
          </Box>
        </ModalBody>

        <ModalFooter>
          <ModalFooterActions>
            <Button variant="secondary" onClick={handleDeleteClose}>
              Cancel
            </Button>
            <Button variant="destructive" onClick={handleDeleteCloseConfirm}>
              Delete Holiday
            </Button>
          </ModalFooterActions>
        </ModalFooter>
      </Modal>
    </>
  );
};
