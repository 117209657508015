import { DateTime } from 'luxon';

interface DateTimeConverterOptions {
  format?: 'full' | 'date' | 'time' | 'csv';
}

export const convertUTCToLocal = (
	utcDateString: string | undefined,
	options: DateTimeConverterOptions = { format: 'full' }
): string => {
	if (!utcDateString) return '';

	try {
		const dateTime = DateTime.fromFormat(utcDateString, "MMM d yyyy 'at' h:mm a", {
			zone: 'utc'
		}).toLocal();

		if (!dateTime.isValid) {
			console.error('Invalid date conversion:', {
				input: utcDateString,
				invalidReason: dateTime.invalidReason
			});
			return utcDateString;
		}

		switch (options.format) {
		case 'csv':
			return dateTime.toFormat("MMM d yyyy 'at' h:mm a");
		case 'date':
			return dateTime.toLocaleString(DateTime.DATE_SHORT);
		case 'time':
			return dateTime.toLocaleString(DateTime.TIME_24_WITH_SECONDS);
		default:
			return dateTime.toLocaleString(DateTime.DATETIME_SHORT);
		}
	} catch (error) {
		console.error('Error converting date:', {
			input: utcDateString,
			error: error instanceof Error ? error.message : String(error)
		});
		return utcDateString;
	}
};

export const convertTimeStringToLocal = (
	timeString: string | undefined,
): string => {
	if (!timeString) return '';

	try {
		const milliseconds = timeString.split('.')[1];
		const timeWithoutMilliseconds = timeString.split('.')[0];

		const dateTime = DateTime.fromFormat(timeWithoutMilliseconds, "HH:mm:ss", {
			zone: 'utc'
		});

		const localDateTime = dateTime.toLocal();

		if (!dateTime.isValid) {
			console.error('Invalid time conversion:', {
				input: timeString,
				invalidReason: dateTime.invalidReason
			});
			return timeString;
		}

		const result = `${localDateTime.toFormat("HH:mm:ss")}.${milliseconds}`;

		return result;
	} catch (error) {
		console.error('Error converting time:', {
			input: timeString,
			error: error instanceof Error ? error.message : String(error)
		});
		return timeString;
	}
};